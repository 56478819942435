@import "../../../styles/base/abstracts";

.hbs-social-media-links {
  display: flex;
}

.hbs-social-media-link {
  @include color-link;
  border: 1px solid transparent;
  border-radius: 50%;
  display: inline-flex;
  padding: $spacing-xxs;
  transition: $transition;

  @include hover {
    border: $border;
  }
}

.hbs-social-media-link__text {
  @include hide;
}

.hbs-social-media-link__icon {
  @include size(1.75em);
}
